/// add package
$('.sub_add_package').click(function(){
  var cur_items = $('.calculator_wrap .more_items').attr('data-items');
  var new_item = parseInt(cur_items) + 1;

  var html = '';
  html += '<div class="item" data-item="'+ new_item +'">';
  html += '<div class="field count_wrap">';
  html += '<input type="number" name="count_'+ new_item +'" min="0" id="count" class="small calc" required value="1">';
  html += '</div>';

  html += '<div class="field size_wrap">';
  html += '<input type="number" min="0" name="length_'+ new_item +'" id="length" placeholder="cm*" class="small calc length" required>';
  html += '<input type="number" min="0" name="width_'+ new_item +'" id="width" placeholder="cm*" class="small calc width" required>';
  html += '<input type="number" min="0" name="height_'+ new_item +'" id="height" placeholder="cm*" class="small calc last height" required>';
  html += '</div>';

  html += '<i class="fa fa-times" data-item="'+ new_item +'"></i>';
  html += '</div>';

  $('.calculator_wrap .more_items').attr('data-items', new_item);
  $('.calculator_wrap .more_items').append(html);

  $('input[name="items"]').val($('.calculator_wrap .more_items .item').length);
});

$(document).on('click', '.calculator_wrap .more_items .fa-times', function() {
  var item = $(this).attr('data-item');
  $('.calculator_wrap .more_items .item[data-item="' + item +'"]').remove();
  $('input[name="items"]').val($('.calculator_wrap .more_items .item').length);

  calc_volume();
});

$(document).on('click', '.calculator .type li', function() {
  $('.calculator .type li').removeClass('active');
  $(this).addClass('active');

  calc_volume();
});

$(document).on('change', '.calculator input', function() {
  calc_volume();
});

function calc_volume(){
  var type = $('.calculator .type li.active').attr('data-type');
  var name = $('.calculator .type li.active').attr('data-name');
  var total_m3 = 0;

  var packs = [];

  $('.calculator_wrap .item').each(function(){
    var i = $(this).attr('data-item');
    var data = {};
    data.count = $('.calculator_wrap .item[data-item="'+ i +'"] input#count').val();
    data.width = $('.calculator_wrap .item[data-item="'+ i +'"] input#width').val();
    data.height = $('.calculator_wrap .item[data-item="'+ i +'"] input#height').val();
    data.length = $('.calculator_wrap .item[data-item="'+ i +'"] input#length').val();

    packs.push(data);
    i++;
  });

  var cur_items = $('.calculator_wrap .more_items').attr('data-items');
  var x = (cur_items - 1);

  $('.calcs').html('');

  $.each(packs, function(k, v) {
    count = parseFloat(v.count);
    width = parseFloat(v.width);
    length = parseFloat(v.length);
    height = parseFloat(v.height);

    if(count > 0 &&  width > 0 && length > 0 && v.height > 0) {
      m3 = count * width * length * height / 1000000;

      total_m3 += m3;

      $('.calculator_wrap .calcs').append('<span data-item="'+ x +'">' + count + 'x'+ length +'x' + width + 'x' + height + ' cm = ' + m3 + 'm3</span>');
    }

    x++;
  });

  if($('.calcs span').length > 0) {
    $('.total_vol').show();
    $('#total_vol').html((Math.round((total_m3) * 100) / 100) + 'm3');
    $('.no_weight').hide();
  } else {
    $('.total_vol').hide();
    $('.no_weight').show();
  }

  $('.calculator_wrap h4').html(name);  

  if(type == 'plane' || type == 'road') {
    if(total_m3 > 0) {
      $('.total_weight').show();
      $('#total_weight').html( (Math.round((total_m3) * 100) / 100) + 'x167=' + (Math.round((total_m3 * 167) * 100) / 100) + 'kg');
    } else {
      $('.total_weight').hide();
    }
  }

  if(type == 'courier') {
    if(total_m3 > 0) {
      $('.total_weight').show();
      $('#total_weight').html( (Math.round((total_m3) * 100) / 100) + 'x200=' + (Math.round((total_m3 * 200) * 100) / 100) + 'kg');
    } else {
      $('.total_weight').hide();
    }
  }

  if(type == 'sea') {
      $('.total_weight').hide();
  }

}